import { combineReducers } from 'redux';
import menu from './menu/reducer';

import dashboard from "store/dashboard";
import { patient, patientTotalPage } from "store/patient";
import { insurance, insuranceTotalPage, insuranceMetrics, insuranceDashboard, insuranceDashboardTotalPage } from "store/insurance";
import { partnership, partnershipTotalPage } from "store/partnership";
import { vitalSigns, vitalSignsTotalPage, allVitalSignsByPatient } from "store/vital-signs";
import { record, recordTotalPage, allRecordByPatient } from "store/record";
import { document, documentTotalPage } from "store/document";
import { schedule, scheduleTotalPage } from "store/schedule";
import { shift, shiftTotalPage } from "store/shift";
import { equipment, equipmentTotalPage, equipmentClinic, equipmentClinicTotalPage } from "store/equipment"
import { property, propertyTotalPage, propertyClinic, propertyClinicTotalPage } from "store/property"
import { employee, employeeTotalPage } from "store/employee";
import { division, divisionTotalPage } from "store/division";
import { queue, queueTotalPage } from "store/queue";
import { disease, diseaseTotalPage } from 'store/disease';
import { accounting, accountingTotalPage, accountingCategory, accountingCategoryTotalPage, accountingTransactions, accountingTransactionsTotalPage, accountingTarget, accountingTargetTotalPage, accountingReconcile, accountingReconcileTotalPage } from 'store/accounting';
import { medService, medServiceTotalPage, medServiceClinic, medServiceClinicTotalPage } from 'store/med-service';
import { medicine, medicineTotalPage, medicineList, medicineListTotalPage, medicineClinic, medicineClinicTotalPage, medicineInventory, medicineInventoryTotalPage } from 'store/medicine';
import { consumables, consumablesTotalPage, consumablesClinic, consumablesClinicTotalPage, consumablesService, consumablesServiceTotalPage, consumablesInventory, consumablesInventoryTotalPage } from 'store/consumables';
import { lab, labTotalPage, labServices, labServicesTotalPage } from 'store/lab';
import { hospital, hospitalTotalPage, hospitalDivision, hospitalDivisionTotalPage, hospitalDepartment, hospitalDepartmentTotalPage } from 'store/hospital';
import { inspect, inspectTotalPage, inspectList, inspectListTotalPage } from 'store/inspect';
import { treatment, treatmentTotalPage, treatmentList, treatmentListTotalPage, treatmentPrice, treatmentPriceTotalPage } from 'store/treatment';
import { service, serviceTotalPage, serviceList, serviceListTotalPage, servicePrice, servicePriceTotalPage, serviceClinic, serviceClinicTotalPage, serviceConsumables, serviceConsumablesTotalPage, serviceMedService, serviceMedServiceTotalPage, serviceMedicine, serviceMedicineTotalPage } from 'store/service';
import { bundle, bundleTotalPage, bundleList, bundleListTotalPage, bundleClinic, bundleClinicTotalPage, bundleConsumables, bundleConsumablesTotalPage, bundleService, bundleServiceTotalPage, bundleMedicine, bundleMedicineTotalPage } from 'store/bundle';
import { diagnoseReference, diagnoseReferenceTotalPage, divisionReference, divisionReferenceTotalPage, hospitalReference, hospitalReferenceTotalPage } from 'store/reference';
import { action, actionTotalPage } from 'store/action';
import { vendor, vendorTotalPage } from 'store/vendor';
import { recordDocumentTotalPage, allRecordDocumentByPatient } from 'store/record-document';
import { clinic, clinicTotalPage } from "store/clinic";

import { province, city, subdistrict, ward } from 'store/address';

const reducers = combineReducers({
  menu,
  dashboard,
  patient, patientTotalPage, allRecordByPatient,
  insurance, insuranceTotalPage, insuranceMetrics, insuranceDashboard, insuranceDashboardTotalPage,
  partnership, partnershipTotalPage,
  queue, queueTotalPage,
  vitalSigns, vitalSignsTotalPage, allVitalSignsByPatient,
  record, recordTotalPage,
  document, documentTotalPage,
  schedule, scheduleTotalPage,
  shift, shiftTotalPage,
  equipment, equipmentTotalPage, equipmentClinic, equipmentClinicTotalPage,
  property, propertyTotalPage, propertyClinic, propertyClinicTotalPage,
  employee, employeeTotalPage,
  division, divisionTotalPage,
  disease, diseaseTotalPage,
  accounting, accountingTotalPage, accountingCategory, accountingCategoryTotalPage, accountingTransactions, accountingTransactionsTotalPage, accountingTarget, accountingTargetTotalPage, accountingReconcile, accountingReconcileTotalPage,
  medService, medServiceTotalPage, medServiceClinic, medServiceClinicTotalPage,
  medicine, medicineTotalPage, medicineList, medicineListTotalPage, medicineClinic, medicineClinicTotalPage, medicineInventory, medicineInventoryTotalPage,
  consumables, consumablesTotalPage, consumablesClinic, consumablesClinicTotalPage, consumablesService, consumablesServiceTotalPage, consumablesInventory, consumablesInventoryTotalPage,
  lab, labTotalPage, labServices, labServicesTotalPage,
  hospital, hospitalTotalPage, hospitalDivision, hospitalDivisionTotalPage, hospitalDepartment, hospitalDepartmentTotalPage,
  inspect, inspectTotalPage, inspectList, inspectListTotalPage,
  treatment, treatmentTotalPage, treatmentList, treatmentListTotalPage, treatmentPrice, treatmentPriceTotalPage,
  service,  serviceTotalPage, serviceList, serviceListTotalPage, servicePrice, servicePriceTotalPage, serviceClinic, serviceClinicTotalPage, serviceConsumables, serviceConsumablesTotalPage, serviceMedService, serviceMedServiceTotalPage, serviceMedicine, serviceMedicineTotalPage,
  bundle, bundleTotalPage, bundleList, bundleListTotalPage, bundleClinic, bundleClinicTotalPage, bundleConsumables, bundleConsumablesTotalPage, bundleService, bundleServiceTotalPage, bundleMedicine, bundleMedicineTotalPage,
  diagnoseReference, diagnoseReferenceTotalPage,
  divisionReference, divisionReferenceTotalPage,
  hospitalReference, hospitalReferenceTotalPage,
  action, actionTotalPage,
  vendor, vendorTotalPage,
  recordDocumentTotalPage, allRecordDocumentByPatient,
  clinic, clinicTotalPage,

  province,
  city, 
  subdistrict, 
  ward
});

export default reducers;
