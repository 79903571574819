const hospital = (state = [], action) => {
    switch (action.type) {
        case "GET_HOSPITAL":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const hospitalTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_HOSPITAL":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const hospitalDivision = (state = [], action) => {
    switch (action.type) {
        case "GET_HOSPITAL_DIVISION":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const hospitalDivisionTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_HOSPITAL_DIVISION":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const hospitalDepartment = (state = [], action) => {
    switch (action.type) {
        case "GET_HOSPITAL_DEPARTMENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const hospitalDepartmentTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_HOSPITAL_DEPARTMENT":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { hospital, hospitalTotalPage, hospitalDivision, hospitalDivisionTotalPage, hospitalDepartment, hospitalDepartmentTotalPage } ;