const service = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceList = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_LIST":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceListTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_LIST":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const servicePrice = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_PRICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const servicePriceTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_PRICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceClinic = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceClinicTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_CLINIC":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceConsumables = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_CONSUMABLES":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceConsumablesTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_CONSUMABLES":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceMedService = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_MED_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceMedServiceTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_MED_SERVICE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceMedicine = (state = [], action) => {
    switch (action.type) {
        case "GET_SERVICE_MEDICINE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

const serviceMedicineTotalPage = (state = 0, action) => {
    switch (action.type) {
        case "GET_TOTAL_PAGE_SERVICE_MEDICINE":
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}

export { service, serviceTotalPage, serviceList, serviceListTotalPage, servicePrice, servicePriceTotalPage, serviceClinic, serviceClinicTotalPage, serviceConsumables, serviceConsumablesTotalPage, serviceMedService, serviceMedServiceTotalPage, serviceMedicine, serviceMedicineTotalPage  } ;